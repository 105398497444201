import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTareasList() {
  const toast = useToast()

  const refTareasListTable = ref(null)

  const tableColumns = [
    { key: 'id', label: 'ID', sortable: false },
    { key: 'nombre', label: 'Tarea', sortable: false },
    { key: 'parent.nombre', label: 'Encomienda', sortable: false },
    {
      key: 'actions', label: 'Acciones', sortable: false, thStyle: { minWidth: '120px' },
    },
  ]

  const perPage = ref(50)
  const totalTareas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTareasListTable.value ? refTareasListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMateriales.value,
    }
  })

  const refetchData = () => {
    refTareasListTable.value.refresh()
  }

  const deleteData = id => {
    store.dispatch('app-tarea/deleteTareas', id)
    refTareasListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchTareas = (ctx, callback) => {
    store
      .dispatch('app-tarea/fetchTareas', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `tarefas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalTareas.value = total
        callback(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tareas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listTareas = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-tarea/listTareas', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `tarefas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalTareas.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tareas',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  return {
    fetchTareas,
    tableColumns,
    perPage,
    currentPage,
    totalTareas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTareasListTable,

    refetchData,
    listTareas,
    deleteData,
    // Extra Filters
    statusFilter,
  }
}
