import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useZonasList() {
  const toast = useToast()

  const refZonasListTable = ref(null)

  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'nombre', label: 'Zona', sortable: true },
    { key: 'parent.nombre', label: 'Núcleo', sortable: false },
    { key: 'isMantenimiento', label: 'Mantenimiento', sortable: false },
    {
      key: 'actions', label: 'Acciones', sortable: false, thStyle: { minWidth: '150px' },
    },
  ]

  const perPage = ref(5000)
  const totalZonas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refZonasListTable.value ? refZonasListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMateriales.value,
    }
  })

  const refetchData = () => {
    refZonasListTable.value.refresh()
  }

  const deleteData = id => {
    store.dispatch('app-zona/deleteZonas', id)
    refZonasListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchZonas = (ctx, callback) => {
    store
      .dispatch('app-zona/fetchZonas', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `zonas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalZonas.value = total
        callback(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching zonas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listZonas = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-zona/listZonas', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `zonas.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalZonas.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching zonas',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  return {
    fetchZonas,
    tableColumns,
    perPage,
    currentPage,
    totalZonas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refZonasListTable,

    refetchData,
    listZonas,
    deleteData,
    // Extra Filters
    statusFilter,
  }
}
