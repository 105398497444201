import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { materiales } from '@/utils/materiales'

export default function useMaterialesList() {
  // Use toast
  const toast = useToast()

  const refMaterialesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'codigo', label: 'Código', sortable: true },
    { key: 'descripcion', label: 'Descripción', sortable: true },
    { key: 'coste', label: 'Coste', sortable: true },
    { key: 'stock', label: 'Stock', sortable: true },
    { key: 'tipo', label: 'Tipo Material', sortable: true },
    { key: 'medida', label: 'Medida', sortable: true },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'actions', label: 'Acciones', thStyle: { minWidth: '150px' } },
  ]
  const perPage = ref(50)
  const totalMateriales = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialesListTable.value ? refMaterialesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMateriales.value,
    }
  })

  const refetchData = () => {
    refMaterialesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchMateriales = (ctx, callback) => {
    store
      .dispatch('app-materiales/fetchMateriales', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `materiales.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalMateriales.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listMateriales = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-materiales/listMateriales', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': 'materiales.descripcion',
          'X-Sort-Order': 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalMateriales.value = total
        const filterActive = res.data.filter(el => el.estado === 'activo')
        if (res.data.length > filterActive.length) {
          totalMateriales.value -= (res.data.length - filterActive.length)
        }
        resolve(filterActive)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveStatusVariant = status => {
    if (status === 'activo') return 'success'
    if (status === 'inactivo') return 'danger'
    if (status === 'reparacion') return 'warning'
    if (status === 'baja') return 'info'
    return 'secondary'
  }

  const resolveStatusText = status => {
    if (status === 'activo') return materiales.estados[0].label
    if (status === 'inactivo') return materiales.estados[1].label
    if (status === 'reparacion') return materiales.estados[2].label
    if (status === 'baja') return materiales.estados[3].label
    return '-'
  }

  return {
    fetchMateriales,
    tableColumns,
    perPage,
    currentPage,
    totalMateriales,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialesListTable,

    refetchData,
    parseDate,
    resolveStatusVariant,
    resolveStatusText,
    listMateriales,

    // Extra Filters
    statusFilter,
  }
}
